<template>
  <div>
    <b-row
      class="content-header"
    >

      <!-- Content Left -->
      <b-col
        class="content-header-left mb-2"
        cols="12"
        md="12"
      >
        <b-row class="breadcrumbs-top">
          <b-col cols="12">
            <h1 class="custom-header-title float-left pr-1 mb-0">
              Preview Stickers
            </h1>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Table Container Card -->
    <div>
      <div
        class="sticker-view-vertical__step"
      >
        <b-row>
          <b-col
            cols="3"
            xl="3"
            md="3"
            sm="3"
            lg="3"
            class="text-left d-block my-auto"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="link"
              :disabled="currentPage == 0"
              @click="changePreview('sub')"
            >
              <feather-icon
                icon="ChevronLeftIcon"
                class="pt-25"
                size="18"
              />
              <span class="align-middle">Prev</span>
            </b-button>
          </b-col>
          <b-col
            cols="6"
            xl="6"
            md="6"
            sm="6"
            lg="6"
            class="text-center"
          >
            <b-dropdown
              id="dropdown-1"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              split
            >
              <template #button-content>
                <feather-icon
                  icon="FileIcon"
                  size="18"
                  class="align-middle mr-1"
                  :class="{'step-mobile__font-adjust' : isMobile()}"
                />
                <span
                  class="mr-1"
                  :class="{'step-mobile__font-adjust' : isMobile()}"
                >{{ (currentPage + 1).toString().padStart(2, "0") }} / {{ totalPage ? totalPage.toString().padStart(2, "0") : '01' }}
                  <span>: Print Stickers</span>
                </span>
              </template>
              <b-dropdown-item>
                Print Stickers {{ totalPage }} - {{ currentPage + 1 }}
              </b-dropdown-item>
            </b-dropdown>
          </b-col>
          <b-col
            cols="3"
            xl="3"
            md="3"
            sm="3"
            lg="3"
            class="text-right d-block my-auto"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="link"
              :disabled="(currentPage + 1) == totalPage"
              @click="changePreview('add')"
            >
              <span class="align-middle">Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                class="pt-25"
                size="18"
              />
            </b-button>
          </b-col>
        </b-row>
      </div>
      <div
        class="print-sticker__vertical"
      >
        <div class="print-sticker__wrapper">
          <div
            class="print-sticker__content-wrapper"
            :class="{ 'p-0' : printStickerType === 82 }"
          >
            <template v-if="currentPage == 0">
              <div
                v-for="(skipSticker, skipIndex) in skipStickerNo"
                :key="'before_8'+skipIndex"
              >
                <div
                  :class="printStickerType === 82 ? 'single-sticker__block-size-two' : 'single-sticker__block'"
                />
              </div>
            </template>
            <div
              v-for="(sticker, sIndex) in previewStickerData"
              :key="'sticker_8'+sIndex"
              :class="printStickerType === 82 ? 'single-sticker__block-size-two' : 'single-sticker__block'"
            >
              <b-button
                v-if="(sticker.paymentStatus && sticker.paymentStatus !== 'Paid')"
                class="sticker-status__btn"
              >
                {{ sticker.paymentStatus }}
              </b-button>
              <b-img
                class="sticker__logo"
                src="@/assets/images/logo/sticker-logo.png"
              />
              <div class="sticker-text__block">
                <small>{{ dateFormatWithTime(sticker.date) }}</small>
                <div class="sticker-text__heading">
                  <h5>{{ sticker.keyName }}</h5>
                </div>
                <div class="stock-text__line" />
                <p>{{ sticker.workFlowName }}</p>
                <p style="font-size: 8px;">
                  {{ sticker.remarks }}
                </p>
                <p>{{ sticker.location }}</p>
                <p>{{ sticker.customerName }}</p>
              </div>
            </div>
            <template v-if="((currentPage + 1) == totalPage) && (previewStickerData.length < 8)">
              <div
                v-for="(skipSticker, skipIndex) in (currentPage == 0 ? (8 - (previewStickerData.length + skipStickerNo)) : 8 - previewStickerData.length)"
                :key="'after_8'+skipIndex"
              >
                <div
                  :class="printStickerType === 82 ? 'single-sticker__block-size-two' : 'single-sticker__block'"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>

    <div
      class="print-sticker__eight-page d-eight-page"
    >
      <table>
        <tbody>
          <tr
            v-for="(trData, trIndex) in printStickersArray"
            :key="trIndex"
          >
            <td
              v-for="(sticker, sIndex) in trData"
              :key="'sticker_8'+sIndex"
            >
              <div
                v-if="sticker.customerName"
                :class="printStickerType === 82 ? 'table-single-sticker__block-size-two' : 'table-single-sticker__block'"
              >
                <b-button
                  v-if="(sticker.paymentStatus && sticker.paymentStatus !== 'Paid')"
                  class="sticker-status__btn"
                >
                  {{ sticker.paymentStatus }}
                </b-button>
                <b-img
                  class="sticker__logo"
                  src="@/assets/images/logo/sticker-logo.png"
                />
                <div class="sticker-text__block">
                  <small>{{ dateFormatWithTime(sticker.date) }}</small>
                  <div class="sticker-text__heading">
                    <h5>{{ sticker.keyName }}</h5>
                  </div>
                  <div class="stock-text__line" />
                  <p>{{ sticker.workFlowName }}</p>
                  <p style="font-size: 8px;">
                    {{ sticker.remarks }}
                  </p>
                  <p>{{ sticker.location }}</p>
                  <p>{{ sticker.customerName }}</p>
                </div>
              </div>
              <div
                v-else
                :class="printStickerType === 82 ? 'table-single-sticker__block-size-two' : 'table-single-sticker__block'"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <b-nav class="wrap-border save-nav">
      <li
        class="nav-item ml-auto"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="flat-primary"
          :to="{ name: 'operation-service-requests-kitchen-view', params: { type: 'all-service-requests' } }"
        >
          Cancel
        </b-button>
      </li>
      <li
        class="nav-item mr-1"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          type="button"
          variant="success"
          :disabled="printStickers.length > 0 ? false : true"
          @click="printSticker()"
        >
          <feather-icon
            icon="SaveIcon"
            class="mr-50"
          />
          <span class="align-middle">Print Stickers</span>
        </b-button>
      </li>
    </b-nav>
  </div>
</template>

<script>
import {
  BRow, BCol, BImg, BButton, BNav, BDropdown, BDropdownItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BRow,
    BCol,
    BImg,
    BButton,
    BNav,
    BDropdown,
    BDropdownItem,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      stickerType: Number(localStorage.getItem('stickerType') || '8'),
      printStickerType: Number(localStorage.getItem('stickerType') || '8'),
      skipStickerNo: Number(JSON.parse(localStorage.getItem('skipStickerNo')) || 0),
      serviceRequestItems: JSON.parse(localStorage.getItem('serviceRequestItems')) || [],
      serviceRequests: [],
      printStickers: [],
      printStickersArray: [],
      previewStickerData: [],
      kitchenItems: [],
      currentPage: 0,
      totalPage: 0,
      pageLength: 0,
      startIndex: 0,
      endIndex: Number(localStorage.getItem('stickerType') || '8'),
      printDataLength: 0,
      lastPagePrintItem: 0,
      venueField: '',
      requestField: '',
    }
  },
  beforeRouteLeave(to, from, next) {
    localStorage.removeItem('stickerType')
    localStorage.removeItem('skipStickerNo')
    localStorage.removeItem('serviceRequestItems')
    next()
  },
  created() {
    const formData = new FormData()
    formData.append('srs', JSON.stringify(this.serviceRequestItems))
    this.$http.post('operation/service-requests/kitchen-view/print/preview', formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(response => {
        this.serviceRequests = response.data.serviceRequests
        this.kitchenItems = response.data.kitchenItems
        this.venueField = response.data.venueField ?? ''
        this.requestField = response.data.requestField ?? ''
        this.stickerPrintData()
      })
      .catch(error => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: error.data.message || process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  },
  methods: {
    getLocationFromDescription(data) {
      if (data && data.length) {
        const venue = data.find(o => o.key === this.venueField)
        if (venue && Array.isArray(venue.value) && venue.value.length) {
          return venue.value[0]
        }
      }
      return ''
    },
    getRequestFromDescription(data) {
      if (data && data.length) {
        const request = data.find(o => o.key === this.requestField)
        if (request && request.value) {
          return request.value
        }
      }
      return ''
    },
    stickerPrintData() {
      this.serviceRequests.forEach(srItem => {
        const location = this.getLocationFromDescription(srItem.serviceDescription)
        const remarks = this.getRequestFromDescription(srItem.serviceDescription)
        srItem.serviceDescription.forEach(item => {
          const checkKitchenItem = this.resolveKitchenItems(item)
          if (checkKitchenItem) {
            const stickerObject = {
              date: srItem.date,
              paymentStatus: srItem.paymentStatus,
              keyName: item.key,
              workFlowName: srItem.workFlowName,
              location,
              remarks,
              customerName: srItem.customerName,
            }
            // eslint-disable-next-line no-plusplus
            for (let i = 0; i < checkKitchenItem; i++) {
              this.printStickers.push(stickerObject)
            }
          }
        })
      })

      if (this.stickerType === 81 || this.stickerType === 82) { this.stickerType = 8; this.endIndex = 8 }
      if (this.stickerType === 41 || this.stickerType === 42) { this.stickerType = 4; this.endIndex = 4; this.pageSize = 'A4 landscape' }

      this.pageLength = this.printStickers.length
      this.printDataLength = this.printStickers.length + this.skipStickerNo
      this.printDataLastIndex = this.printDataLength - 1
      this.totalPage = Math.ceil((this.pageLength + this.skipStickerNo) / this.stickerType)

      if (this.skipStickerNo > 0) {
        this.endIndex = this.stickerType - this.skipStickerNo
      }
      this.lastPagePrintItem = (this.totalPage * this.stickerType) - this.printDataLength
      this.previewStickerData = this.printStickers.slice(this.startIndex, this.endIndex)
      this.printStickersArray = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.skipStickerNo; i++) {
        const lastIndex = this.printStickersArray[this.printStickersArray.length - 1]
        if (lastIndex && lastIndex.length < 2) {
          const obj = {}
          this.printStickersArray[this.printStickersArray.length - 1].push(obj)
        } else {
          const objArray = []
          const obj = {}
          objArray.push(obj)
          this.printStickersArray.push(objArray)
        }
      }

      this.printStickers.forEach(item => {
        const lastIndex = this.printStickersArray[this.printStickersArray.length - 1]
        if (lastIndex && lastIndex.length < 2) {
          this.printStickersArray[this.printStickersArray.length - 1].push(item)
        } else {
          const objArray = []
          objArray.push(item)
          this.printStickersArray.push(objArray)
        }
      })
    },
    resolveKitchenItems(item) {
      const found = this.kitchenItems.find(p => p.id === item.id)
      if (found) {
        let counter = 0
        if (item.type === 'file') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'title-and-text') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'short-answer') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'long-answer') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.type === 'multi-select') {
          if (item.value.length) {
            /* eslint-disable-next-line no-plusplus */
            counter += item.value.length
          }
        } else if (item.type === 'single-select') {
          if (item.value) {
            /* eslint-disable-next-line no-plusplus */
            counter++
          }
        } else if (item.value) {
          /* eslint-disable-next-line no-plusplus */
          counter += item.value
        }

        return counter
      }

      return 0
    },
    changePreview(option) {
      if (option === 'add') {
        this.currentPage += 1
      } else {
        this.currentPage -= 1
      }

      if (this.skipStickerNo > 0) {
        if (this.currentPage > 0) {
          this.startIndex = (this.currentPage * this.stickerType) - this.skipStickerNo
          this.endIndex = this.stickerType + this.startIndex
        }
      } else {
        this.startIndex = this.currentPage * this.stickerType
        this.endIndex = this.stickerType + this.startIndex
      }

      if (this.currentPage === 0) {
        this.startIndex = 0
        this.endIndex = this.stickerType - this.skipStickerNo
      }
      this.previewStickerData = this.printStickers.slice(this.startIndex, this.endIndex)
    },
    printSticker() {
      // window.focus()
      // window.print()
      // window.close()
      const routeUrl = this.$router.resolve({ name: 'operation-service-requests-kitchen-view-print-stickers-eight-two' })
      window.open(routeUrl.href, '_blank')
    },
  },
}
</script>

<style lang="scss">
.d-eight-page{
   display: none;
}
.grid-gap-0{
   gap: 0 !important;
}
.bg-transparent{
     background-color: transparent !important;
}
  .d-eight-page{
   display: none;
}
</style>
